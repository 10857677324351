<template>
    <section class="features py-5 overflow-hidden">
        <div class="container">
            <h1 class="display-4 font-weight-bolder text-center mb-5 font-noto-serif-tc">
                為何選擇3激光纖 810-940-1060nm
            </h1>
            <h3 class="text-center mb-4"
                data-aos="fade"
                data-aos-duration="1000">
                毛色 VS 膚色
            </h3>
            <div class="row">
                <div class="col-md-7 d-flex flex-column justify-content-center mb-3 mb-md-5">
                    <h4 class="font-weight-bolder"
                        data-aos="fade-right"
                        data-aos-duration="1000">
                        毛色：
                    </h4>
                    <h5 class="text-justify"  
                        data-aos="fade-up"
                        data-aos-duration="1000">
                        膚色會影響激光對毛髮黑色素吸引力，毛色要靠毛髮黑色素吸激光，越黑嘅毛越快脫光；
                    </h5>
                </div>
                <div class="col-md-5 mb-5">
                    <img class="feature-img w-100"
                         data-aos="fade-left"
                         data-aos-duration="1000"
                         alt="毛色"
                         src="../../../assets/campaign/elysion-pro-comb/feature-1.svg">
                </div>
                <div class="col-md-7 d-flex flex-column justify-content-center mb-3 mb-md-5">
                    <h4 class="font-weight-bolder"
                        data-aos="fade-right"
                        data-aos-duration="1000">
                        膚色：
                    </h4>
                    <h5 class="text-justify"
                        data-aos="fade-up"
                        data-aos-duration="1000">
                        皮膚都有黑色素，所以皮膚亦會吸收激光，因此，脫毛就要選擇能最大程度殺死毛毛，但又對皮膚傷害最細嘅激光。
                    </h5>
                </div>
                <div class="col-md-5 mb-5">
                    <img class="feature-img w-100"
                         data-aos="fade-left"
                         data-aos-duration="1000"
                         alt="膚色"
                         src="../../../assets/campaign/elysion-pro-comb/feature-2.svg">
                </div>
            </div>
            <h5 class="font-weight-normal text-center mb-5"
                data-aos="fade-up"
                data-aos-duration="1000">
                Elysion-Pro 3激光纖 810-940-1060nm就有效應用於I-VI型顏色皮膚毛髮粗幼人士。
            </h5>
        </div>
    </section>
</template>

<script>
    export default {
        name: "ElysionProCombFeatures",
        data () {
            return {

            };
        },
        computed: {

        },
        methods: {

        },
    }
</script>

<style lang="scss" scoped>
    @import "./variables.scss";

    .features {
        background-color: $background-color;
        color: $text-color;

        .feature-img {
            border: solid 10px #e57a30;
            border-radius: 15px;
        }
    }
</style>
